import Image from "next/image";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";

export const CarouselCustom = styled(Carousel)`
  & > .carousel-indicators > button {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #fff;
    @media only screen and (max-width: 800px) {
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  & > .carousel-indicators > .active {
    background-color: #da364f;
    opacity: 1;
    width: 8px;
    height: 8px;
    @media only screen and (max-width: 800px) {
      width: 0.6rem;
      height: 0.4rem;
    }
  }
`;

export const CarouselItem = styled(Carousel.Item)``;

export const CustomImag = styled(Image)`
  width: 100%;
  height: 15.3rem;
  object-fit: cover;
  border-top-left-radius: ${({ theme }) => (theme.btlr ? theme.btlr : "5px")};
  border-top-right-radius: ${({ theme }) => (theme.btrr ? theme.btrr : "5px")};
  border-bottom-left-radius: ${({ theme }) => (theme.bblr ? theme.bblr : "0")};
  border-bottom-right-radius: ${({ theme }) => (theme.bbrr ? theme.bbrr : "0")};
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
`;
