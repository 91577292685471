import { Card, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  margin: 0.8rem;
  padding: 0;
  display: contents;
  box-shadow: 0 0 10px 0 #ccc;
  cursor: ${(props) => (props.theme.isClickeable ? "pointer" : "context-menu")};
  @media only screen and (max-width: 768px) {
    ${({ theme }) => {
      if (theme.isTransparent) return "display:flex;  box-shadow: none; ";
      return "";
    }};
  }
`;
export const CustomCard = styled(Card)`
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  //min-width: 19rem;
  //max-height: 23rem;
  /* margin: 0 30px; */
`;
export const CustomCardImg = styled(Card.Img)`
  height: 12rem;

  background-size: cover;
`;
export const CustomCardBody = styled(Card.Body)`
  @media only screen and (max-width: 992px) {
    padding: 0rem 1rem !important;
  }
`;

export const TitleRow = styled(Row)`
  display: flex;
`;
export const TitleCol = styled(Col)``;

export const InfoRow = styled(Row)``;
export const InfoCol = styled(Col)``;
//planes
export const TitleIcon = styled.img`
  padding-top: 1.3rem;
  width: 1.3rem;
`;
export const DateLabel = styled.p``;

export const ColorSpan = styled.span`
  color: ${(props) => (props.theme.fontColor ? props.theme.fontColor : "#000")};
  font-weight: 500;
  margin-top: 1rem;
  width: 3rem;
  height: 1.2rem;
  background-color: ${(props) => props.theme.bgColor};
  border-radius: 9px;
  padding-left: 1.7rem;
  background-image: ${(props) =>
    props.theme.img ? `url(${props.theme.img})` : ""};
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 32px 15px;
  margin-left: 0.5rem;
`;

export const CustomLabel = styled.p`
  color: ${(props) => (props.theme.color ? props.theme.color : "#000")};
  font-size: ${(props) => (props.theme.fSize ? props.theme.fSize : "14px")};
  font-weight: ${(props) =>
    props.theme.fWeight ? props.theme.fWeight : "600"};
`;

export const BodyCard = styled.div`
  margin: 0;
  padding: 0;
  display: block;
  /* max-width: 17rem;
  max-height: 25rem; */
  min-width: 17rem;
  max-width: ${({ theme }) => (theme.mwidth ? theme.mwidth : "17rem")};
  min-height: ${(props) =>
    props.theme.customHeight ? props.theme.customHeight : "23rem"};
  max-height: ${(props) =>
    props.theme.customHeight ? props.theme.customHeight : "23rem"};
  background-color: ${({ theme }) =>
    theme.isTransparent ? "trasparent" : "#fff"};
  border-radius: 10px;
  box-shadow: ${({ theme }) =>
    theme.isTransparent ? "none" : "1px 1px 10px 1px #ccc"};
  margin-bottom: 1rem;
  @media only screen and (max-width: 992px) {
    //min-height: 20rem;
    //max-height: 20rem;
  }
`;

export const ResortTopRow = styled(Row)`
  max-height: ${({ theme }) => (theme.mHeight ? theme.mHeight : "5rem")};
  min-height: 2rem;
  margin-top: ${({ theme }) => (theme.mtop ? theme.mtop : "0")};
  margin-bottom: ${({ theme }) => (theme.mBottom ? theme.mBottom : "0")};
`;
export const ResortTopCol = styled(Col)`
  color: ${({ theme }) => (theme.color ? theme.color : "#575757")};
  padding: 1.3rem;
  padding-left: 2rem;
  font-weight: ${({ theme }) => (theme.weight ? theme.weight : "700")};
  font-size: ${({ theme }) => (theme.size ? theme.size : "1.2rem")};
`;

export const ResortSubTopCol = styled(Col)`
  color: ${({ theme }) => (theme.color ? theme.color : "#575757")};
  /* margin: 1.5rem; */
  padding: 2rem;
  font-weight: ${({ theme }) => (theme.weight ? theme.weight : "700")};
  font-size: ${({ theme }) => (theme.size ? theme.size : "1.2rem")};
`;

export const ResortTopTitle = styled.p`
  color: ${({ theme }) => (theme.color ? theme.color : "#575757")};
  padding: 1rem;
  font-weight: ${({ theme }) => (theme.weight ? theme.weight : "700")};
  font-size: ${({ theme }) => (theme.size ? theme.size : "1.2rem")};
`;
export const ResortTopSubTitle = styled.p``;
