// import { Card } from "react-bootstrap";
import styled from "styled-components";
import Image from "next/image";
// import { Row } from "react-bootstrap";

export const CustomCardImg = styled(Image)`
  border-top-left-radius: ${({ theme }) => (theme.btlr ? theme.btlr : "5px")};
  border-top-right-radius: ${({ theme }) => (theme.btrr ? theme.btrr : "5px")};
  border-bottom-left-radius: ${({ theme }) => (theme.bblr ? theme.bblr : "0")};
  border-bottom-right-radius: ${({ theme }) => (theme.bbrr ? theme.bbrr : "0")};
`;

export const ImgWrap = styled.div`
  padding: ${({ theme }) => (theme.padding ? theme.padding : "0")};
  margin-bottom: ${({ theme }) => (theme.mbottom ? theme.mbottom : "0")};
`;
