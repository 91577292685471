export const HOTEL = "hotel";
export const RESORT = "resort";
export const PROMOTIONS = "promotions";
export const DESTINATIONS = "destinations";
export const FLIGHT = "flight";
export const ADS = "ads";
export const CAR = "car";
export const RESORTLARGE = "resortLarge";
export const RESORTLARGE2 = "resortLargeTwo";
export const SIZENORMAL = "normal";
export const SIZELARGE = "large";
export const PARTNER = "partner";
export const VOYVIO = "voyvio";
export const CRUISES = "cruises";
