import { ADS, VOYVIO } from "@data/cardsConstant";
import {
  checkCookies,
  getCookie,
  setCookies,
  removeCookies,
} from "cookies-next";
import { CarouselCustom, CarouselItem, CustomImag } from "./styles";
import Router from "next/router";
import { FLIGHT_SEARCH_INVENTORY_TYPE } from "@helpers/config";
import { getAutoSuggestV2 } from "@lib/api/flights/autosuggest";
import { useHotelSearchDetailStore } from "store/hotelSearchDetailStore";
import { useAuth } from "@contexts/AuthContext";
import { useDinamicUrl } from "@hooks/useAds";
import { MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE } from "@helpers/helperAds";
type CarouselCardImageType = {
  type: string;
  images: string | string[];
  imgHeight?: string;
  onClickEvent: () => void;
  urlPath?: string | UrlLabelType;
  buttonText?: string;
};
export const CarouselCardImage = ({
  type,
  images,
  imgHeight,
  buttonText,
  urlPath,
}: CarouselCardImageType) => {
  let { getUserInfoByKey } = useDinamicUrl();
  const { isLogged, currentUser } = useAuth();
  const { resetHotelSearchByRoomOcupancyHotelCards } =
    useHotelSearchDetailStore();
  const setAdsCoordinates = async (url: string) => {
    removeCookies("searchLatitude");
    removeCookies("searchLongitude");
    let nurl = new URLSearchParams(url);
    let query: CardBodyFlighQuery = Array.from(nurl.keys()).reduce(
      (sum, value) => {
        return Object.assign({ [value]: nurl.get(value) }, sum);
      },
      {}
    );

    switch (type) {
      case "flight":
        if (query.latitude && query.longitude) {
          setCookies("searchLatitude", query.latitude);
          setCookies("searchLongitude", query.longitude);
        } else {
          if (query && query?.flightDesktopValueReturnCode) {
            let json = {
              text: query.flightDesktopValueReturnCode,
              latitude: 0,
              longitude: 0,
              type: FLIGHT_SEARCH_INVENTORY_TYPE,
              language: String(getCookie("lan_code") || "en-US"),
            };

            const responseV2 = await getAutoSuggestV2(json);

            if (responseV2.length > 0) {
              setCookies("searchLatitude", responseV2[0].latitude);
              setCookies("searchLongitude", responseV2[0].longitude);
            }
          }
        }

        break;
      case "hotel":
        setCookies("searchLatitude", query.latitude);
        setCookies("searchLongitude", query.longitude);
        break;
    }
  };

  const handleRedirectUrl = (urlObj: string | UrlLabelType | undefined) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    var urlRedirect: string | undefined = "";
    var defaultUrlRedirect: string | undefined = "";
    let findUrl = false;

    if (urlObj) {
      if (typeof urlObj === "object") {
        for (let [key, value] of Object.entries(urlObj)) {
          if (key === code) {
            urlRedirect = value;
          }
          if (key === "en") {
            defaultUrlRedirect = value;
          }
        }
      } else {
        urlRedirect = urlObj;
      }
    }

    if (!findUrl) {
      urlRedirect = defaultUrlRedirect;
    }

    setAdsCoordinates(urlRedirect);
    let firstLetters = urlRedirect.substring(0, 3);
    if (urlRedirect !== "") {
      //section URL whith special params
      const isFilledUrl = urlRedirect.match(
        MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE
      );
      if (isLogged && currentUser) {
        if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
          let tmpurlRedirect = getUserInfoByKey(
            urlRedirect,
            isFilledUrl,
            currentUser
          );
          if (tmpurlRedirect) {
            urlRedirect = tmpurlRedirect;
          }
        }
      }

      if (firstLetters.includes("htt") || firstLetters.includes("ww")) {
        resetHotelSearchByRoomOcupancyHotelCards();
        window.location.replace(`${urlRedirect}`);
      } else {
        if (Router.pathname === "/search-detail") {
          Router.replace({
            pathname: `/search-detail`,
            query: `${urlRedirect}`,
          }).then(() => Router.reload());
        } else {
          resetHotelSearchByRoomOcupancyHotelCards();

          if (urlRedirect.startsWith("/search-detail")) {
            window.location.replace(
              `${window.location.origin}${decodeURI(urlRedirect)}`
            );
            return;
          }
          setTimeout(() => {
            Router.push({
              pathname: `/search-detail`,
              query: `${urlRedirect}`,
            });
          }, 100);
        }
      }
    }
  };

  const redirectWithOutButton = () => {
    if ([undefined, "", null].includes(buttonText)) {
      handleRedirectUrl(urlPath);
    }
  };

  const borderRadius = () => {
    if (type === ADS) {
      return { btlr: "0px", btrr: "0px", bblr: "0px", bbrr: "0px" };
    }
    if (type === VOYVIO) {
      return { btlr: "30px", btrr: "30px", bblr: "30px", bbrr: "30px" };
    }

    return { btlr: "5px", btrr: "5px", bblr: "0px", bbrr: "0px" };
  };

  const imageHeight = () => {
    if (type === VOYVIO) {
      return "60";
    }

    return "70px";
  };

  return (
    <>
      {Array.isArray(images) && images?.length === 1 ? (
        <CustomImag
          theme={{
            height: imgHeight,
            isAd: type === ADS,
            btlr: borderRadius().btlr,
            btrr: borderRadius().btrr,
            bblr: borderRadius().bblr,
            bbrr: borderRadius().bbrr,
          }}
          src={images[0]}
          alt=""
          width={"100"}
          height={imgHeight ?? imageHeight()}
          layout="responsive"
          onClick={() => redirectWithOutButton()}
          loading="lazy"
          placeholder="blur"
          blurDataURL="/images/modalSignInSignUp/photoNotFound.png"
        />
      ) : (
        <CarouselCustom interval={null}>
          {Array.isArray(images)
            ? images?.length > 0 &&
              images?.map((img, idx: number) => {
                return (
                  <CarouselItem key={`carousel-img-item-${idx}`}>
                    <CustomImag
                      theme={{
                        height: imgHeight,
                        isAd: type === ADS,
                        btlr: borderRadius().btlr,
                        btrr: borderRadius().btrr,
                        bblr: borderRadius().bblr,
                        bbrr: borderRadius().bbrr,
                      }}
                      src={img}
                      alt=""
                      width={"100"}
                      height={imgHeight ?? imageHeight()}
                      layout="responsive"
                      // priority={true}
                      onClick={() => redirectWithOutButton()}
                      loading="lazy"
                      placeholder="blur"
                      blurDataURL="/images/modalSignInSignUp/photoNotFound.png"
                    />
                  </CarouselItem>
                );
              })
            : null}
        </CarouselCustom>
      )}
    </>
  );
};
