import axios from "axios";

export const getAutoSuggest = async (
  val: string
): Promise<FlightAutoSuggestType[]> => {
  try {
    const response = await axios.post(`/api/flights/autosuggest`, {
      val,
    });
    return response?.data?.suggestion;
  } catch (error) {
    console.info(error);
    return [];
  }
};

export const getAutoSuggestV2 = async (
  json: Object
): Promise<FlightAutoSuggestTypeV2[]> => {
  try {
    const response = await axios.post(`/api/flights/autosuggestV2`, {
      json,
    });
    return response?.data?.list;
  } catch (error) {
    console.info(error);
    return [];
  }
};
