import { ADS, VOYVIO } from "@data/cardsConstant";
import { CustomCardImg, ImgWrap } from "./styles";
import {
  checkCookies,
  getCookie,
  removeCookies,
  setCookies,
} from "cookies-next";
import Router from "next/router";
import { useState } from "react";
import { defaultNotFound } from "@data/imagesNotFound";
import { getAutoSuggestV2 } from "@lib/api/flights/autosuggest";
import { FLIGHT_SEARCH_INVENTORY_TYPE } from "@helpers/config";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { useHotelSearchDetailStore } from "store/hotelSearchDetailStore";
import { useAuth } from "@contexts/AuthContext";
import { MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE } from "@helpers/helperAds";
import { useDinamicUrl } from "@hooks/useAds";

type CardImageType = {
  type: string;
  img: string;
  imgHeight?: string;
  onClickEvent: () => void;
  urlPath?: string | UrlLabelType;
  buttonText?: string;
  setDateContext: (url: string) => void;
  typeImg?: boolean;
  isLargeAd: boolean;
  adSize: string;
  showTopTitle: boolean;
};

type ImgConfigType = {
  wrapPadding: string;
  width: string;
  height: string;
  mbottom: string;
  objectFit: "fill" | "cover";
};
export const CardImage = ({
  type,
  img,
  imgHeight,
  buttonText,
  urlPath,
  typeImg,
  isLargeAd,
  adSize,
  showTopTitle,
  setDateContext,
}: CardImageType) => {
  let { getUserInfoByKey } = useDinamicUrl();
  const { isLogged, currentUser } = useAuth();
  const [errorImage, setErrorImage] = useState(false);
  const { useWeeksV2 } = useLayoutStore(
    (state) => ({
      useWeeksV2: state.externalConfig?.useWeeksV2,
    }),
    shallow
  );
  const { resetHotelSearchByRoomOcupancyHotelCards } =
    useHotelSearchDetailStore();
  const basePage = (type: string) => {
    switch (type) {
      case "hotel":
        return "/search-detail";
        break;
      case "car":
        return "cars/search-details";
        break;
      case "flight":
        return "flight/search-details";
        break;

      default:
        return "/search-detail";
    }
  };

  const defaultImgSelector = () => {
    switch (type) {
      case "hotel":
        return "/images/notFound/HotelMain-01.jpg";
        break;
      case "car":
        return "/images/notFound/CarMain-01.jpg";
        break;
      case "flight":
        return "/images/general/no-img-found.png";
        break;

      default:
        return defaultNotFound;
    }
  };

  const setAdsCoordinates = async (url: string) => {
    let nurl = new URLSearchParams(url);
    removeCookies("searchLatitude");
    removeCookies("searchLongitude");

    let query: CardBodyFlighQuery = Array.from(nurl.keys()).reduce(
      (sum, value) => {
        return Object.assign({ [value]: nurl.get(value) }, sum);
      },
      {}
    );

    switch (type) {
      case "flight":
        if (query.latitude && query.longitude) {
          setCookies("searchLatitude", query.latitude);
          setCookies("searchLongitude", query.longitude);
        } else {
          if (query && query?.flightDesktopValueReturnCode) {
            let json = {
              text: query.flightDesktopValueReturnCode,
              latitude: 0,
              longitude: 0,
              type: FLIGHT_SEARCH_INVENTORY_TYPE,
              language: String(getCookie("lan_code") || "en-US"),
            };
            const responseV2 = await getAutoSuggestV2(json);
            console.log("responseV2", responseV2);
            if (responseV2.length > 0) {
              setCookies("searchLatitude", responseV2[0].latitude);
              setCookies("searchLongitude", responseV2[0].longitude);
            }
          }
        }

        break;
      case "hotel":
        if (query.latitude) {
          setCookies("searchLatitude", query.latitude);
          setCookies("searchLongitude", query.longitude);
        }

        break;
    }
  };

  const handleRedirectUrl = (urlObj: string | UrlLabelType | undefined) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    var urlRedirect: string | undefined = "";
    var defaultUrlRedirect: string | undefined = "";
    let findUrl = false;

    if (urlObj) {
      if (typeof urlObj === "object") {
        for (let [key, value] of Object.entries(urlObj)) {
          if (key === code) {
            urlRedirect = value;
          }
          if (key === "en") {
            defaultUrlRedirect = value;
          }
        }
      } else {
        urlRedirect = urlObj;
      }
    }

    if (!findUrl) {
      urlRedirect = defaultUrlRedirect;
    }

    setDateContext(urlRedirect);
    setAdsCoordinates(urlRedirect);

    if (urlRedirect !== "") {
      //section URL whith special params
      const isFilledUrl = urlRedirect.match(
        MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE
      );
      if (isLogged && currentUser) {
        if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
          let tmpurlRedirect = getUserInfoByKey(
            urlRedirect,
            isFilledUrl,
            currentUser
          );
          if (tmpurlRedirect) {
            urlRedirect = tmpurlRedirect;
          }
        }
      }

      let firstLetters = urlRedirect.substring(0, 3);

      if (type === "resort") {
        if (urlRedirect.includes("resort")) {
          const baseUrl = decodeURIComponent(urlRedirect);

          if (useWeeksV2) {
            // * for resortsv2
            Router.push(`/${baseUrl}`);
            return;
          }

          Router.push({
            pathname: `/weeks`,
            query: `redirect=/${baseUrl}`,
          });
          return;
        }

        if (firstLetters.includes("htt") || firstLetters.includes("ww")) {
          resetHotelSearchByRoomOcupancyHotelCards();
          window.location.replace(`${urlRedirect}`);
        }
      } else {
        if (firstLetters.includes("htt") || firstLetters.includes("ww")) {
          // Router.push({
          //   pathname: `${urlRedirect}`,
          // });
          resetHotelSearchByRoomOcupancyHotelCards();
          window.location.replace(`${urlRedirect}`);
        } else {
          let finalPathname = urlRedirect.includes("search-detail")
            ? ""
            : basePage(type);

          if (Router.pathname.includes("search-detail")) {
            Router.replace({
              pathname: `${finalPathname}`,
              query: `${urlRedirect}`,
            }).then(() => Router.reload());
          } else {
            resetHotelSearchByRoomOcupancyHotelCards();

            if (urlRedirect.startsWith("/search-detail")) {
              window.location.replace(
                `${window.location.origin}${decodeURI(urlRedirect)}`
              );
              return;
            }

            setTimeout(() => {
              Router.push({
                pathname: `${finalPathname}`,
                query: `${urlRedirect}`,
              });
            }, 100);
          }
        }
      }
    }
  };

  const redirectWithOutButton = () => {
    console.log("buttonText, typeof buttonText", buttonText, typeof buttonText);
    if ([undefined, "", null].includes(buttonText)) {
      handleRedirectUrl(urlPath);
    }
  };

  const borderRadius = () => {
    if (isLargeAd) {
      if (adSize == "large" && !showTopTitle) {
        return { btlr: " 10px", btrr: "10px", bblr: "0px", bbrr: "0px" };
      }
      return { btlr: "0px", btrr: "0px", bblr: "0px", bbrr: "0px" };
    } else {
      if (type === ADS) {
        return { btlr: "0px", btrr: "0px", bblr: "0px", bbrr: "0px" };
      }
      if (type === VOYVIO) {
        return { btlr: "30px", btrr: "30px", bblr: "30px", bbrr: "30px" };
      }
    }

    return { btlr: "5px", btrr: "5px", bblr: "0px", bbrr: "0px" };
  };

  const imgConfig = (): ImgConfigType => {
    if (type === "car") {
      if (typeof typeImg !== "undefined" && typeImg) {
        return {
          wrapPadding: "2rem",
          width: "700px",
          height: "400px",
          mbottom: "-2rem",
          objectFit: "fill",
        };
      }
      return {
        wrapPadding: "0rem",
        width: "200px",
        height: "500px",
        mbottom: "0rem",
        objectFit: "fill",
      };
    }

    return {
      wrapPadding: "",
      width: "100",
      height: imgHeight ?? "60",
      mbottom: "",
      objectFit: "cover",
    };
  };

  const imageHeight = () => {
    // console.log("adSize - showTopTitle", adSize, showTopTitle);

    if (adSize == "large" && !showTopTitle) {
      return "250px";
    }
    return "150px";
  };

  return (
    <ImgWrap
      theme={{ padding: imgConfig().wrapPadding, mbottom: imgConfig().mbottom }}
    >
      <CustomCardImg
        theme={{
          // height: imgHeight,
          isAd: type === ADS,
          btlr: borderRadius().btlr,
          btrr: borderRadius().btrr,
          bblr: borderRadius().bblr,
          bbrr: borderRadius().bbrr,
        }}
        // objectFit={imgConfig()?.objectFit || "cover"}
        objectPosition={"50% 30%"}
        width={"272px"}
        height={imageHeight()}
        // layout="responsive"
        // priority={true}
        src={!errorImage ? img : defaultImgSelector()}
        alt="card-main-img"
        onClick={() => {
          redirectWithOutButton();
        }}
        onError={() => {
          setErrorImage(true);
          console.log("onerror");
        }}
        loading="lazy"
        placeholder="blur"
        blurDataURL="/images/modalSignInSignUp/photoNotFound.png"
      />
    </ImgWrap>
  );
};
