import { CarouselCardImage } from "./Header/CarouselImage";
import { CardImage } from "./Header/SingleImage";
import Router from "next/router";
import {
  BodyCard,
  CustomCardBody,
  MainContainer,
  ResortTopRow,
  ResortTopCol,
  ResortSubTopCol,
} from "./styles";
import {
  DESTINATIONS,
  FLIGHT,
  HOTEL,
  PROMOTIONS,
  RESORT,
  ADS,
  CAR,
  RESORTLARGE,
  RESORTLARGE2,
  SIZELARGE,
  PARTNER,
  VOYVIO,
  CRUISES,
} from "@data/cardsConstant";
import { urlQueryToJson, getDatesFromRange } from "@helpers/helper";
import { useGlobalContext } from "@hooks/useGlobalContext";
import { useEffect, useState } from "react";
import { FLIGHT_SEARCH_INVENTORY_TYPE, IMAGE_PATH } from "@helpers/config";

import {
  checkCookies,
  getCookie,
  removeCookies,
  setCookies,
} from "cookies-next";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import dynamic from "next/dynamic";
import { getAutoSuggestV2 } from "@lib/api/flights/autosuggest";
import { useHotelSearchDetailStore } from "@store/hotelSearchDetailStore";
import { MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE } from "@helpers/helperAds";
import { useAuth } from "@contexts/AuthContext";
import { useDinamicUrl } from "@hooks/useAds";

const HotelBody = dynamic(() => import("@components/Cards/Body/Hotel"), {
  ssr: false,
});
const PromotionsBody = dynamic(
  () => import("@components/Cards/Body/Promotions"),
  {
    ssr: false,
  }
);
const ResortBody = dynamic(() => import("@components/Cards/Body/Resort"), {
  ssr: false,
});
const DestinationBody = dynamic(
  () => import("@components/Cards/Body/Destination"),
  {
    ssr: false,
  }
);
const FlightBody = dynamic(() => import("@components/Cards/Body/Flight"), {
  ssr: false,
});
const CarBody = dynamic(() => import("@components/Cards/Body/Car"), {
  ssr: false,
});
const ResortBottomText = dynamic(
  () => import("@components/Cards/Body/Resort2"),
  {
    ssr: false,
  }
);
const PartnerBody = dynamic(() => import("@components/Cards/Body/Partner"), {
  ssr: false,
});
const VoyvioBody = dynamic(() => import("@components/Cards/Body/Voyvio"), {
  ssr: false,
});
const CruiseBody = dynamic(() => import("@components/Cards/Body/Cruise"), {
  ssr: false,
});

type MainCardDataType = {
  data: MainCardType;
  size?: string;
};
export const MainCard: React.FC<MainCardDataType> = ({
  data,
  size = "normal",
}) => {
  let { getUserInfoByKey } = useDinamicUrl();
  const { isLogged, currentUser } = useAuth();
  const { currencyRate, useWeeksV2 } = useLayoutStore(
    (state) => ({
      useWeeksV2: state.externalConfig?.useWeeksV2,
      currencyRate: state.currencyRate,
    }),
    shallow
  );
  const { resetHotelSearchByRoomOcupancyHotelCards } =
    useHotelSearchDetailStore();

  const { search, setSearch } = useGlobalContext();
  const [cardType, setCardType] = useState<string>(data?.type);

  const [adImg, setAdImg] = useState("small");
  const [showTitleSection, setShowTitleSection] = useState(false);

  const getPrice = (priceOrigin: number) => {
    try {
      if (currencyRate && priceOrigin) {
        return (currencyRate * priceOrigin).toFixed(2);
      }
      return priceOrigin.toFixed(2);
    } catch (error) {
      return String(priceOrigin || "0");
    }
  };
  const getCurrencyCode = (currencyOrigin?: string) => {
    if (currencyRate) {
      const currentCurrency = checkCookies("currency_code")
        ? String(getCookie("currency_code"))
        : currencyOrigin;

      return currentCurrency;
    }
    return currencyOrigin;
  };
  const largeAdConfiguration = () => {
    if (
      !["", 0, null, undefined].includes(data.checkInDate) &&
      !["", 0, null, undefined].includes(data.checkOutDate)
    ) {
      if (data.type == RESORT) {
        if (
          !["", 0, null, undefined].includes(data.offerTitle) ||
          !["", 0, null, undefined].includes(textValidation(data.offerSubtitle))
        ) {
          setAdImg("small");
          setShowTitleSection(true);
        }
      } else {
        setAdImg("large");
        if (
          !["", 0, null, undefined].includes(data.offerTitle) ||
          !["", 0, null, undefined].includes(textValidation(data.offerSubtitle))
        ) {
          setShowTitleSection(true);
        } else {
          setShowTitleSection(false);
        }
      }
    } else {
      if (
        !["", 0, null, undefined].includes(data.offerTitle) ||
        !["", 0, null, undefined].includes(textValidation(data.offerSubtitle))
      ) {
        setAdImg("small");
        setShowTitleSection(true);
      } else {
        setAdImg("large");
        setShowTitleSection(false);
      }
    }
  };

  const isNullDataToCard = () => {
    if (data.checkInDate && data.checkOutDate) {
      return true;
    }
    return false;
  };

  const hasOfferType = () => {
    //const offerSubtitle = "price too low";
    if (data.offerTitle || textValidation(data.offerSubtitle)) {
      return true;
    }
    return false;
  };

  const imageHeight = () => {
    switch (data?.type) {
      case HOTEL:
        if (size === SIZELARGE) {
          // if (!isNullDataToCard()) {
          //   return "95rem";
          // }

          // if (!hasOfferType()) {
          //   return "95rem";
          // }
          if (adImg === "large") {
            return "95rem";
          }
          return "60rem";
        }
        return "60rem";
        //Array.isArray(data?.image) ? "60rem" : "60rem";
        break;
      case FLIGHT:
        return "60rem";
        //Array.isArray(data?.image) ? "60rem" : "60rem";
        break;
      case DESTINATIONS:
        return "60rem";
        //Array.isArray(data?.image) ? "60rem" : "60rem";
        break;
      case RESORT:
        if (size === SIZELARGE) {
          if (!isNullDataToCard()) {
            return "95rem";
          }

          if (!hasOfferType()) {
            return "100rem";
          }
          return "60rem";
        }
        return "60rem";
        //Array.isArray(data?.image) ? "60rem" : "60rem";
        break;
      case PROMOTIONS:
        return "50rem";
        //Array.isArray(data?.image) ? "50rem" : "50rem";
        break;
      case RESORTLARGE:
        return "60rem";
        //Array.isArray(data?.image) ? "50rem" : "50rem";
        break;
      case RESORTLARGE2:
        return "95rem";
        break;
      case CAR:
        return "50rem";
        break;
      case ADS:
        return "100%";
        // Array.isArray(data?.image)
        //   ? data?.adSize === "small"
        //     ? "100%"
        //     : "200%"
        //   : data?.adSize === "small"
        //   ? "100%"
        //   : "200%";
        break;
    }
  };

  const CardBody = (type: string) => {
    if (size === SIZELARGE && !isNullDataToCard() && type !== PARTNER) {
      return (
        <ResortBottomText
          title={String(textValidation(data?.title))}
          subtitle={String(textValidation(data?.subtitle))}
          urlPath={
            data?.buttonText ? data?.url : urlBodyV2Button(data.url)?.url
          }
          buttonText={
            data?.buttonText
              ? textValidation(data?.buttonText)
              : urlBodyV2Button(data.url)?.text
          }
        />
      );
    }
    switch (type) {
      case HOTEL:
        return (
          <HotelBody
            image={data?.image}
            type={data?.type}
            themes={data?.themes ?? []}
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            b2b={getPrice(Number(data?.b2b) || data?.price)}
            // price={data?.priceInfo}
            priceConditions={data?.priceConditions}
            score={data?.score}
            scoreLabel={data?.scoreLabel}
            stars={data?.stars}
            discount={data?.discount}
            currency={getCurrencyCode(data?.currency)}
            nights={data?.nights}
            checkInDate={data?.checkInDate}
            checkOutDate={data?.checkOutDate}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            // sectionName="hotel-body"
            // themes={themes}
            // title=" Cancun, MX."
            // subtitle="Mayan Palace"
            // b2b="200"
            // priceInfo="150"
            // priceConditions="per night"
            // score="8.5"
            // stars="4.5"
          />
        );
        break;
      case FLIGHT:
        return (
          <FlightBody
            type={data?.type}
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            date_departure={data?.dateDeparture}
            date_return={data?.dateReturn}
            persons={data?.persons}
            bags={data?.bags}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
          />
        );
        break;
      case DESTINATIONS:
        if (data.checkInDate == "") {
          return (
            <PartnerBody
              title={String(textValidation(data?.title))}
              subtitle={textValidation(data?.subtitle)}
              buttonText={
                data?.buttonText
                  ? textValidation(data?.buttonText)
                  : urlBodyV2Button(data.url)?.text
              }
              urlPath={
                data?.buttonText ? data?.url : urlBodyV2Button(data.url)?.url
              }
            />
          );
        }
        return (
          <DestinationBody
            type={data?.type}
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            price={getPrice(data?.price)}
            currency={getCurrencyCode(data?.currency)}
            priceConditions={data?.priceConditions}
            score={data?.score}
            scoreLabel={data?.scoreLabel}
            stars={data?.stars}
            urlPath={data?.url}
            checkInDate={data?.checkInDate}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            // sectionName="destination-body"
            // title=" Los Angeles,CA U.S."
            // subtitle="Los Angeles a sprawling city center of the film and TV"
            // priceInfo="From $80 per day"
            // priceConditions="On Reservation before June 4,2022"
          />
        );
        break;
      case RESORT:
        return (
          <ResortBody
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            priceInfo={data?.priceInfo}
            priceConditions={data?.priceConditions}
            score={data?.score}
            scoreLabel={data?.scoreLabel}
            stars={data?.stars}
            price={Number(getPrice(data?.price))}
            currency={getCurrencyCode(data?.currency)}
            nights={data?.nights}
            maxOccupancy={data?.maxOccupancy}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            checkInDate={data?.checkInDate}
            checkOutDate={data?.checkOutDate}
            // type="resort-body"
            // title="Bali, Indonesia"
            // subtitle="forested volcanic mountains, iconic rice paddies, beaches"
            // priceInfo="Up to 50% off"
            // priceConditions="On Reservation before June 4,2022"
          />
        );
        break;
      case CRUISES:
        return (
          <CruiseBody
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            priceInfo={data?.priceInfo}
            priceConditions={data?.priceConditions}
            score={data?.score}
            scoreLabel={data?.scoreLabel}
            stars={data?.stars}
            price={Number(getPrice(data?.price))}
            currency={getCurrencyCode(data?.currency)}
            nights={data?.nights}
            maxOccupancy={data?.maxOccupancy}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            checkInDate={data?.checkInDate}
            checkOutDate={data?.checkOutDate}
            // type="resort-body"
            // title="Bali, Indonesia"
            // subtitle="forested volcanic mountains, iconic rice paddies, beaches"
            // priceInfo="Up to 50% off"
            // priceConditions="On Reservation before June 4,2022"
          />
        );
        break;
      case RESORTLARGE:
        return (
          <ResortBody
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            priceInfo={data?.priceInfo}
            priceConditions={data?.priceConditions}
            score={data?.score}
            scoreLabel={data?.scoreLabel}
            stars={data?.stars}
            price={Number(getPrice(data?.price))}
            currency={getCurrencyCode(data?.currency)}
            nights={data?.nights}
            maxOccupancy={data?.maxOccupancy}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            checkInDate={data?.checkInDate}
            checkOutDate={data?.checkOutDate}
          />
        );
        break;
      case PROMOTIONS:
        return (
          <PromotionsBody
            type={data?.type}
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            // type="promotion-body"
            // title="Apple AirPods Pro"
            // subtitle="Active noise cancellation for immersive sound"
            // link="Show Now"
          />
        );
        break;
      case CAR:
        return (
          <CarBody
            type={data?.type}
            title={textValidation(data?.title)}
            subtitle={textValidation(data?.subtitle)}
            subtitle2={textValidation(data?.subtitle2)}
            priceInfo={data?.priceInfo}
            priceConditions={data?.priceConditions}
            brandImage={data?.brandImage}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            buttonUrl={data?.buttonUrl}
            days={data?.days ?? 1}
          />
        );
        break;
      case RESORTLARGE2:
        return (
          <ResortBottomText
            title={String(textValidation(data?.title))}
            subtitle={String(textValidation(data?.subtitle))}
          />
        );
        break;
      case PARTNER:
        return (
          <PartnerBody
            title={String(textValidation(data?.title))}
            subtitle={textValidation(data?.subtitle)}
            buttonText={textValidation(data?.buttonText)}
            urlPath={data?.url}
            isLarge={size === SIZELARGE}
          />
        );
        break;
      case VOYVIO:
        return (
          <VoyvioBody
            title={String(textValidation(data?.title))}
            subtitle={textValidation(data?.subtitle)}
            buttonText={textValidation(data?.buttonText)}
            urlPath={data?.url}
          />
        );
        break;
    }
  };

  const handdleImageEvent = () => {
    if (data.type === ADS) {
    }
  };

  const handleCardHeight = () => {
    if (data?.type === HOTEL) {
      if (size === SIZELARGE) {
        return "33rem";
      }
      return "22rem";
    }

    if (data?.type === RESORT) {
      if (size === SIZELARGE) {
        return "33rem";
      }
      return "23rem";
    }

    if (data?.type === PROMOTIONS) {
      return "17rem";
    }

    if ([RESORTLARGE, RESORTLARGE2].includes(data?.type)) {
      return "33rem";
    }

    if (data?.type === ADS) {
      // if (data?.adSize === "large") {
      //   return "34rem";
      // }
      return "17rem"; //small size
    }

    if ([CAR, FLIGHT].includes(data?.type)) {
      if (size === SIZELARGE) {
        return "33rem";
      }
      return "22rem";
    }

    if (data?.type === PARTNER) {
      if (size === SIZELARGE) {
        return "33rem";
      }
      return "23rem";
    }
    return "23rem";
  };

  const validateImageUrl = (img: string) => {
    if (img === "undefined") {
      return "/images/modalSignInSignUp/photoNotFound.png";
    }

    let firstLetters = img.substring(0, 3);
    if (firstLetters.includes("//")) {
      return `https:${img}`;
    }

    if (firstLetters.includes("htt")) {
      return String(img);
    }
    return `${IMAGE_PATH}${img}`;
  };

  const fixImgArray = (images: string[]) => {
    const imgFix: string[] = [];

    images.map((img) => {
      imgFix.push(validateImageUrl(img));
    });

    return imgFix;
  };

  const handleSetDateOnContext = (urlRedirect: string) => {
    const params = urlQueryToJson(urlRedirect);
    console.log("urlObj-- dateFromRange", params, params.dateRange);

    if (params.dateRange) {
      const rangeDates = getDatesFromRange(params.dateRange);
      setSearch({
        ...search,
        dates: {
          checkIn: rangeDates.date1,
          checkOut: rangeDates.date2,
        },
      });
    }

    setSearch({ ...search, search_text: params.location });
  };

  const bodyCardSize = () => {
    if (["voyvio"].includes(cardType)) {
      return "23rem";
    }

    return "17rem";
  };

  const textValidation = (
    textValue: string | JsonType | UrlLabelType | undefined
  ) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    var urlRedirect: string | undefined = "";
    if (textValue) {
      let finalCode = textValue.hasOwnProperty(code) ? code : "en";

      if (typeof textValue === "object") {
        for (let [key, value] of Object.entries(textValue)) {
          if (key === finalCode) {
            urlRedirect = value;
          }
        }
        if (!urlRedirect && Object.keys(textValue).length > 0) {
          let value = Object.values(textValue)[0];
          urlRedirect = value ?? " ";
        }
      } else {
        urlRedirect = textValue;
      }
    }
    return urlRedirect ?? "";
  };

  const urlBodyV2Button = (url: string | AdsBannerUrl | UrlLabelType) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    let buttonInfo = undefined;

    if (Array.isArray(url) && url.length > 0) {
      buttonInfo = url.find((f) => f.code === code);

      if (!buttonInfo) {
        buttonInfo = url[0];
      }
    }

    return buttonInfo;
  };

  const setAdsCoordinates = async (url: string) => {
    let nurl = new URLSearchParams(url);
    removeCookies("searchLatitude");
    removeCookies("searchLongitude");

    let query: CardBodyFlighQuery = Array.from(nurl.keys()).reduce(
      (sum, value) => {
        return Object.assign({ [value]: nurl.get(value) }, sum);
      },
      {}
    );
    let type = data?.type;
    switch (type) {
      case "flight":
        if (query.latitude && query.longitude) {
          setCookies("searchLatitude", query.latitude);
          setCookies("searchLongitude", query.longitude);
        } else {
          if (query && query?.flightDesktopValueReturnCode) {
            let json = {
              text: query.flightDesktopValueReturnCode,
              latitude: 0,
              longitude: 0,
              type: FLIGHT_SEARCH_INVENTORY_TYPE,
              language: String(getCookie("lan_code") || "en-US"),
            };
            const responseV2 = await getAutoSuggestV2(json);
            console.log("responseV2", responseV2);
            if (responseV2.length > 0) {
              setCookies("searchLatitude", responseV2[0].latitude);
              setCookies("searchLongitude", responseV2[0].longitude);
            }
          }
        }

        break;
      case "hotel":
        if (query.latitude) {
          setCookies("searchLatitude", query.latitude);
          setCookies("searchLongitude", query.longitude);
        }

        break;
    }
  };

  const handleRedirectUrl = (urlObj: string | UrlLabelType | undefined) => {
    let lanCode = String(getCookie("lan_code"));
    let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
    var urlRedirect: string | undefined = "";
    var defaultUrlRedirect: string | undefined = "";
    let findUrl = false;
    let type = data?.type;

    if (urlObj) {
      if (typeof urlObj === "object") {
        for (let [key, value] of Object.entries(urlObj)) {
          if (key === code) {
            urlRedirect = value;
          }
          if (key === "en") {
            defaultUrlRedirect = value;
          }
        }
      } else {
        urlRedirect = urlObj;
      }
    }

    if (!findUrl) {
      urlRedirect = defaultUrlRedirect;
    }

    handleSetDateOnContext(urlRedirect);
    setAdsCoordinates(urlRedirect);

    if (urlRedirect !== "") {
      //section URL whith special params
      const isFilledUrl = urlRedirect.match(
        MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE
      );
      if (isLogged && currentUser) {
        if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
          let tmpurlRedirect = getUserInfoByKey(
            urlRedirect,
            isFilledUrl,
            currentUser
          );
          if (tmpurlRedirect) {
            urlRedirect = tmpurlRedirect;
          }
        }
      }

      let firstLetters = urlRedirect?.substring(0, 3);

      if (type === "resort") {
        if (urlRedirect?.includes("resort")) {
          const baseUrl = decodeURIComponent(urlRedirect);

          if (useWeeksV2) {
            // * for resortsv2
            Router.push(`/${baseUrl}`);
            return;
          }

          Router.push({
            pathname: `/weeks`,
            query: `redirect=/${baseUrl}`,
          });
          return;
        }

        if (firstLetters?.includes("htt") || firstLetters?.includes("ww")) {
          resetHotelSearchByRoomOcupancyHotelCards();
          window.location.replace(`${urlRedirect}`);
        }
      } else {
        if (firstLetters?.includes("htt") || firstLetters?.includes("ww")) {
          // Router.push({
          //   pathname: `${urlRedirect}`,
          // });
          resetHotelSearchByRoomOcupancyHotelCards();
          window.location.replace(`${urlRedirect}`);
        } else {
          if (Router.pathname.includes("search-detail")) {
            Router.replace({
              pathname: `${basePage(type)}`,
              query: `${urlRedirect}`,
            }).then(() => Router.reload());
          } else {
            resetHotelSearchByRoomOcupancyHotelCards();
            setTimeout(() => {
              Router.push({
                pathname: `${basePage(type)}`,
                query: `${urlRedirect}`,
              });
            }, 100);
          }
        }
      }
    }
  };

  const basePage = (type: string) => {
    switch (type) {
      case "hotel":
        return "/search-detail";
        break;
      case "car":
        return "cars/search-details";
        break;
      case "flight":
        return "flight/search-details";
        break;

      default:
        return "/search-detail";
    }
  };

  useEffect(() => {
    if (size === SIZELARGE && hasOfferType() && isNullDataToCard()) {
      setCardType("ads");
    }
    largeAdConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.type]);

  return (
    <MainContainer
      theme={{
        isClickeable: data?.buttonText === undefined ? true : false,
        isTransparent: ["voyvio"].includes(cardType),
      }}
    >
      <BodyCard
        theme={{
          customHeight: handleCardHeight(),
          isTransparent: ["voyvio"].includes(cardType),
          mwidth: bodyCardSize(),
        }}
      >
        {size === SIZELARGE && showTitleSection && (
          <div
            onClick={() => {
              handleRedirectUrl(data?.url);
            }}
          >
            <ResortTopRow
              theme={{
                mHeight:
                  textValidation(data.offerTitle).length < 25 ? "2rem" : "4rem",
              }}
            >
              <ResortTopCol xs={12}>
                {textValidation(data.offerTitle)}
              </ResortTopCol>
            </ResortTopRow>
            <ResortTopRow
              theme={{
                mtop:
                  textValidation(data?.offerTitle).length < 45
                    ? "0rem"
                    : "1.5rem",
                mBottom:
                  textValidation(data?.offerSubtitle).length > 35
                    ? "1rem"
                    : "0rem",
              }}
            >
              <ResortSubTopCol
                className="ellipsis"
                data-text={data.offerSubtitle}
                xs={12}
                theme={{
                  size: "0.9rem",
                  color: "#575757",
                  weight: "600",
                  strLen: data?.offerSubtitle?.length ?? 0,
                }}
              >
                {textValidation(data.offerSubtitle)}
              </ResortSubTopCol>
            </ResortTopRow>
          </div>
        )}
        {Array.isArray(data?.image) ? (
          <CarouselCardImage
            type={data.type}
            images={fixImgArray(data?.image)}
            imgHeight={imageHeight()}
            onClickEvent={handdleImageEvent}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
          />
        ) : (
          <CardImage
            type={data?.type}
            img={validateImageUrl(String(data?.image))}
            imgHeight={imageHeight()}
            onClickEvent={handdleImageEvent}
            urlPath={data?.url}
            buttonText={textValidation(data?.buttonText)}
            setDateContext={handleSetDateOnContext}
            isLargeAd={size === SIZELARGE && !isNullDataToCard()}
            typeImg={data?.typeImg}
            adSize={size}
            showTopTitle={size === SIZELARGE && showTitleSection}
          />
        )}
        {data?.type !== ADS && (
          <CustomCardBody>{CardBody(data?.type)}</CustomCardBody>
        )}
      </BodyCard>
    </MainContainer>
  );
};
